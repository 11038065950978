import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RegistrationForm from './RegistrationForm';
import './App.css';
import checkIcon from './logo.png';
import companyLogo from './company-logo.png';

const PricingPage = ({ onSelectSubscription }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState('month');
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);

  useEffect(() => {
    axios.get('https://api.powexinventory.ch/api/v1/subscription/subscriptions')
      .then(response => setSubscriptions(response.data.payload))
      .catch(error => console.error('Error fetching subscriptions:', error));
  }, []);

  useEffect(() => {
    const filteredSubscriptions = subscriptions.filter(subscription => subscription.interval === selectedInterval);
    setFilteredSubscriptions(filteredSubscriptions);
  }, [selectedInterval, subscriptions]);

  const handleSubscriptionSelect = (subscription) => {
    onSelectSubscription(subscription);
  };

  const getSubscriptionCardClassName = (subscriptionId) => {
    return `subscription-card-${subscriptionId}`;
  };

  if (filteredSubscriptions === null) {
    return <div>Loading...</div>;
  }

  const renderSubscriptions = () => {
    return filteredSubscriptions.map(subscription => (
      <div
        key={subscription.id}
        className={`subscription-card ${getSubscriptionCardClassName(subscription.id)}`}
      >
        <h3>{subscription.name}</h3>
        <p>{subscription.description}</p>
        <p className='price-tag'>
          {subscription.id === "price_1OH5obIyvNykPERRLQfqcEvF" || subscription.id === "price_1OH5o7IyvNykPERRR6m77FVT"
            ? "Auf Anfrage"
            : `${subscription.currency.toUpperCase()} ${subscription.price}.-`
          }
        </p>
        <ul>
          {subscription.features.map((feature, index) => (
            <li className="features-list" key={index}>
              {feature.name && (
                <span>
                  <img src={checkIcon} alt="Check Icon" style={{ width: '20px', height: '18px' }} />&nbsp;
                  <span dangerouslySetInnerHTML={{
                    __html: feature.name.replace(
                      /(5 Lizenzen|Telefon Support|0\.5 h Einführung|10 Lizenzen|1h| Beratung jahrlich|1h|Einführung|Benutzerdefiniertes Importfile|Benutzerdefiniertes Exportfile|ab 11 Lizenzen)/g, '<strong>$1</strong>'
                    ).replace(
                      /(4h|8h|\(inkl.\))/g, '<span style="color: red; font-weight:bold;">$1</span>'
                    )
                  }} />
                </span>
              )}
            </li>
          ))}
        </ul>



        {subscription.id === "price_1OH5obIyvNykPERRLQfqcEvF" || subscription.id === "price_1OH5o7IyvNykPERRR6m77FVT" ? (
          <button onClick={() => window.location.href = "https://powex.ch/index.php/kontakt"} target="_blank" rel="noopener noreferrer">
            Kontakt
          </button>
        ) : (
          <button onClick={() => handleSubscriptionSelect(subscription)}>
            Kaufen
          </button>
        )}
      </div>
    ));
  };

  return (
    <div className="pricing-container">
      <img src={companyLogo} alt="Company Logo" className="company-logo" />
      <h2 className="pricing-title">Preispläne für jeden Bedarf</h2>
      <div className="centered-container">
        <div className="interval-switcher">
          <button
            className={selectedInterval === 'month' ? 'active' : ''}
            onClick={() => setSelectedInterval('month')}
          >
            Monatlich
          </button>
          <button
            className={selectedInterval === 'year' ? 'active' : ''}
            onClick={() => setSelectedInterval('year')}
          >
            Jährlich
          </button>
        </div>
      </div>
      <h3 className='pricing-subtitle'>Bei jährlicher Zahlung 1 Monat kostenlos!</h3>
      <div className="subscriptions-container">{renderSubscriptions()}</div>
      {selectedSubscription && (
        <RegistrationForm selectedSubscription={selectedSubscription} />
      )}
    </div>
  );
};

export default PricingPage;








import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import companyLogo from './company-logo.png';

const RegistrationForm = ({ selectedSubscription }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    requestedModules: [
      {
        moduleId: 2,
      },
    ],
    tosAgreement: false,
    priceId: selectedSubscription ? selectedSubscription.id : '',
    firstname: '',
    lastname: '',
  });

  const [errors, setErrors] = useState({});

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z-'']+$/;
    return nameRegex.test(name);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegistration = () => {
    const requiredFields = ['email', 'password', 'firstname', 'lastname'];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...missingFields.reduce((acc, field) => {
      
          switch (field) {
            case 'firstname':
              acc[field] = `Vorname: Eingabe erforderlich.`;
              break;
            case 'lastname':
              acc[field] = `Nachname: Eingabe erforderlich.`;
              break;
            case 'email':
              acc[field] = `E-Mail-Adresse: Eingabe erforderlich.`;
              break;
              case 'password':
              acc[field] = `Passwort: Eingabe erforderlich.`;
              break;
              default:
          acc[field] = `Dieses Feld ist erforderlich.`;
          }
          return acc;
        }, {}),
      }));
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ email: 'Die E-Mail ist keine gültige E-Mail-Adresse.' });
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setErrors({
        password:
          'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Grossbuchstaben, 1 Zahl und 1 Sonderzeichen enthalten.',
      });
      return;
    }

    if (!formData.tosAgreement) {
      setErrors({ tosAgreement: 'Zustimmung zu den Nutzungsbedingungen: Erforderlich.' });
      return;
    }
    if (!validateName(formData.firstname)) {
      setErrors({
        firstname: 'Vorname: Nur Buchstaben, - und \' sind erlaubt.',
      });
      return;
    }

    if (!validateName(formData.lastname)) {
      setErrors({
        lastname: 'Nachname: Nur Buchstaben, - und \' sind erlaubt.',
      });
      return;
    }

    setErrors({});

    axios
      .post('https://api.powexinventory.ch/api/v1/identity/register', formData)
      .then((response) => {
        window.location.href = response.data.redirectUrl;
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {

          setErrors({ email: 'Benutzer existiert bereits.' });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.payload &&
          error.response.data.errorCode
        ) {

          setErrors(
            error.response.data.payload.errors.reduce((acc, error) => {
              acc[error.fieldName] = error.message;
              return acc;
            }, {})
          );
        } else {
          console.error('Error registering:', error);
        }
      });
  };

  return (
    <div className="registration-page-container">
      <img src={companyLogo} alt="Company Logo" className="company-logo" />

      <div className="registration-form-container">
        <h2 className="registration-form-title">Anmeldeformular</h2>
        <p className="selected-substitle">Ausgewähltes Abonnement</p>
        <div className="registration-form-info">
          {selectedSubscription && (
            <p className="selected-substitle-pack">{`${selectedSubscription.name}`}</p>
          )}
          {selectedSubscription && (
            <p className="selected-substitle-pack-price">{`${selectedSubscription.currency.toUpperCase()} ${selectedSubscription.price}.-`}</p>
          )}
        </div>
        <form className="registration-form">
          <div className="input-container">
            <label>Vorname:</label>
            <input type="text" name="firstname" value={formData.firstname} onChange={handleInputChange} required autoComplete="off" />
          </div>
          <div className="input-container">
            <label>Nachname:</label>
            <input type="text" name="lastname" value={formData.lastname} onChange={handleInputChange} required autoComplete="off" />
          </div>
          <div className="input-container">
            <label>E-Mail:</label>
            <input type="email" name="email" value={formData.email} onChange={handleInputChange} required autoComplete="off" />
          </div>
          <div className="input-container">
            <label>Passwort:</label>
            <input type="password" name="password" value={formData.password} onChange={handleInputChange} required autoComplete="off" />
          </div>
          <div className="input-container-2">
            <p className="tos-link">
              <input
                type="checkbox"
                name="tosAgreement"
                checked={formData.tosAgreement}
                onChange={() => setFormData({ ...formData, tosAgreement: !formData.tosAgreement })}
                required
              />
              Ich akzeptiere die{' '}
              <a
                href="https://drive.google.com/file/d/1fdBvzENfi3TWG93AWhAC6f7O3ic37qya/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nutzungsbedingungen
              </a>
            </p>
          </div>

          {Object.keys(errors).length > 0 && (
            <div className="error-container">
              <ul className="error-message" style={{ color: 'red' }}>
                {Object.entries(errors).map(([fieldName, message]) => (
                  <li key={fieldName}>{` ${message}`}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="input-container">
            <button type="button" onClick={handleRegistration}>
              Registrieren
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;


import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PricingPage from './PricingPage';
import RegistrationForm from './RegistrationForm';
import SuccessPage from './SuccessPage';
import FailedPage from './FailedPage';

const App = () => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleSelectSubscription = (subscription) => {
    setSelectedSubscription(subscription);
  };

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/payment/success" element={<SuccessPage />} />
          <Route path="/payment/failed" element={<FailedPage />} />
          <Route
            path="/"
            element={
              !selectedSubscription ? (
                <PricingPage onSelectSubscription={handleSelectSubscription} />
              ) : (
                <Navigate to="/registration" />
              )
            }
          />
          <Route
            path="/registration"
            element={
              selectedSubscription ? (
                <RegistrationForm selectedSubscription={selectedSubscription} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;




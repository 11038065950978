import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';

const FailedPage = () => {
  return (
    <div style={styles.container}>
      <FontAwesomeIcon icon={faSadTear} style={styles.icon} />
      <h1 style={styles.errorText}>Payment Failed</h1>
      <p>Something went wrong with your payment. Please try again.</p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f44336',
    padding: '20px',
    borderRadius: '8px',
    color: 'white',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '35vh',
    maxWidth: '400px',
  },
  errorText: {
    fontSize: '30px',
  },
  icon: {
    fontSize: '50px', 
    marginBottom: '10px', 
  },
};

export default FailedPage;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';

const SuccessPage = () => {
  return (
    <div style={styles.container}>
      <FontAwesomeIcon icon={faSmile} style={styles.icon} />
      <h1 style={styles.successText}>Payment Successful!</h1>
      <p>Your transaction was successful. Thank you for your payment.</p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#4CAF50',
    padding: '20px',
    borderRadius: '8px',
    color: 'white',
    textAlign: 'center',
    margin: 'auto',
    marginTop: '35vh',
    maxWidth: '400px',
  },
  successText: {
    fontSize: '30px',
  },
  icon: {
    fontSize: '50px', 
    marginBottom: '10px', 
  },
};

export default SuccessPage;

